<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<div class="theaterOneM">
			<div class="theaterOneM-site">
				<div :class="[this.value==1?'theaterOneM-yes':'theaterOneM-no']" @click="landmark">
					FLAGSHIP THEATRES
				</div>
				<div :class="[this.value==2?'theaterOneM-yes':'theaterOneM-no']" @click="east">
					EASTERN CHINA
				</div>
				<div :class="[this.value==3?'theaterOneM-yes':'theaterOneM-no']" @click="south">
					SOUTHERN CHINA
				</div>
				<div :class="[this.value==4?'theaterOneM-yes':'theaterOneM-no']" @click="central">
					CENTRAL CHINA
				</div>
				<div :class="[this.value==5?'theaterOneM-yes':'theaterOneM-no']" @click="north">
					NORTHERN CHINA
				</div>
			</div>
			
			<!-- 地图 -->
			<div>
				<el-row v-if="notData">
					<el-row class="theaterOneM-box" v-for="(i,id) in slideshowData" :key="id">
						<el-row class="elcarouselheight">
							<el-carousel height="315px" arrow="never">
								<el-carousel-item v-for="(item, idx) in img[id]" :key="idx">
									<img :src="theaterNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="slideshowOneM-img"></img>
								</el-carousel-item>
							</el-carousel>
						</el-row>
						<el-row class="theaterOneM-title">
							{{i.theaterName}}
						</el-row>
						<el-row class="theaterOneM-message" v-html="i.bodyContent">
							{{i.bodyContent}}
						</el-row>
					</el-row>
				</el-row>
				<el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="error-img"></el-image>
					</el-row>
					<el-row class="error-message">
						Empty data
					</el-row>
				</el-row>
			</div>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
	// 图片查询接口
	import { selectPictureListById , selectContentListById } from '@/api/index.js'
	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	// 头部导航
	import moveNavigation from '@/components/move/moveNavigation.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'THEATRES',
				// 头部导航图片
				headImg:'/index/school.png',
				value:1,
				// 导航值
				navigationvalue:false,
			
				// 全部数据
				slideshowData:[],
				sildeData:{},
				
				// 华东
				eastSlideshowData:[],
				eastSildeData:{},
				
				// 华南
				southSlideshowData:[],
				southSildeData:{},
				
				// 中心
				centalSlideshowData:[],
				centalSildeData:{},
				
				// 华北
				northSlideshowData:[],
				northSildeData:{},
				top:'',
				
				
				moduleType:'MODULE_LANDMARK',
				img:[],
				notData:true,
				theaterNotLoadImage: "/Mimage/560_315.jpg", //影院图片未加载出来
			}
		},
		components:
		{
			moveHeadLogo,
			moveMeauDialog,
			moveNavigation,
			moveBottomNavigation,
		},
		created()
		{
			if(this.$route.query.item==undefined)
			{
				this.value=1;
			}
			else
			{
				if(this.$route.query.item.moduleId==10)
				{
					this.value=1;
				}
				else if(this.$route.query.item.moduleId==11)
				{
					this.value=2;
				}
				else if(this.$route.query.item.moduleId==12)
				{
					this.value=3;
				}
				else if(this.$route.query.item.moduleId==13)
				{
					this.value=4;
				}
				else
				{
					this.value=5;
				}
			}
			this.getList();
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			// 全部数据
			getList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: this.moduleType
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notData=false;
					}
					else
					{
						this.img=[]
						this.notData=true;
						this.slideshowData=response.data.data;
						this.slideshowData.map((v)=>
						{
							if(v.pictureUrl!=null)
							{
								var pictureUrl={'pictureUrl':v.pictureUrl}
							}
							if(v.pictureUrl2!=null)
							{
								var pictureUrl2={'pictureUrl':v.pictureUrl2}
							}
							if(v.pictureUrl3!=null)
							{
								var pictureUrl3={'pictureUrl':v.pictureUrl3}
							}
							if(v.pictureUrl4!=null)
							{
								var pictureUrl4={'pictureUrl':v.pictureUrl4}
							}
							if(v.pictureUrl5!=null)
							{
								var pictureUrl5={'pictureUrl':v.pictureUrl5}
							}
							let data=[]
							if(pictureUrl5==undefined)
							{
								if(pictureUrl4==undefined)
								{
									if(pictureUrl3==undefined)
									{
										if(pictureUrl2==undefined)
										{
											data.push(pictureUrl)
										}
										else
										{
											data.push(pictureUrl,pictureUrl2)
										}
									}
									else
									{
										data.push(pictureUrl,pictureUrl2,pictureUrl3)
									}
								}
								else
								{
									data.push(pictureUrl,pictureUrl2,pictureUrl3,pictureUrl4)
								}
							}
							else
							{
								data.push(pictureUrl,pictureUrl2,pictureUrl3,pictureUrl4,pictureUrl5)
							}
							this.img.push(data)
						})
					}
				})
			},
			// 地理坐标
			landmark()
			{
				this.value=1;
				this.moduleType='MODULE_LANDMARK'
				this.getList();
			},
			// 华东
			east()
			{
				this.value=2;
				this.moduleType='MODULE_EAST'
				this.getList();
				
			},
			// 华南
			south()
			{
				this.value=3;
				this.moduleType='MODULE_SOUTH'
				this.getList();
			},
			// 华中
			central()
			{
				this.value=4;
				this.moduleType='MODULE_CENTRAL'
				this.getList();
			},
			// 华北
			north()
			{
				this.value=5;
				this.moduleType='MODULE_NORTH'
				this.getList();
			},
			
			// 全部地标
			getSlideshowData()
			{
				this.sildeData=this.slideshowData[0];
			},
			// 轮播图滚动数据
			silde(item)
			{
				this.sildeData=this.slideshowData[item];
			},
			// 华东数据
			getEastSlideshowData()
			{
				this.eastSildeData=this.eastSlideshowData[0];
			},
			eastSilde(item)
			{
				this.eastSildeData=this.eastSlideshowData[item];
			},
			// 华南数据
			getSouthSlideshowData()
			{
				this.southSildeData=this.southSlideshowData[0];
			},
			southSilde(item)
			{
				this.southSildeData=this.southSlideshowData[item];
			},
			// 中国中边数据
			getCentalSlideshowData()
			{
				this.centalSildeData=this.centalSlideshowData[0];
			},
			centalSilde(item)
			{
				this.centalSildeData=this.centalSlideshowData[item];
			},
			// 华北数据
			getNorthSlideshowData()
			{
				this.northSildeData=this.northSlideshowData[0];
			},
			northSilde(item)
			{
				this.northSildeData=this.northSlideshowData[item];
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style>
	.theaterOneM{
		width: 90%;
		margin-left: 5%;
	}
	.theaterOneM-site{
		white-space: nowrap;
		overflow-y: scroll;
		font-size: 24px;
		color:#505050;
	}
	.theaterOneM-site::-webkit-scrollbar 
	{
		display: none; 
	}
	.theaterOneM-yes{
		background-color: #FCD002;
		border: solid 1px #B4B7B6;
		display: inline-block;
		margin-right: 3%;
		padding: 20px 30px 22px 31px;
		border-radius: 10px;
	}
	.theaterOneM-no{
		background-color: #F0EDF1;
		border: solid 1px #B4B7B6;
		display: inline-block;
		margin-right: 3%;
		padding: 20px 30px 22px 31px;
		border-radius: 10px;
	}
	.slideshowOneM-img{
		width: 560px;
		height: 315px;
		border-radius:20px;
	}
	.theaterOneM-box{
		max-width: 750px;
		margin-top: 50px;
		text-align: left;
		overflow: hidden;
	}
	#theaterOneM-eastbox{
		margin-top: 30px;
		text-align: left;
	}
	#theaterOneM-northbox{
		margin-top: 30px;
		text-align: left;
	}
	#theaterOneM-centralbox{
		margin-top: 30px;
		text-align: left;
	}
	#theaterOneM-southbox{
		margin-top: 30px;
		text-align: left;
	}
	.theaterOneM-title{
		margin-top: 20px;
		overflow:none;
		font-size: 30px;
		color:#505050;
	}
	.theaterOneM-message{
		height: 250px;
		margin-top: 30px;
		line-height: 25px;
		
		overflow: hidden;
		overflow-y:scroll;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 7;
		-webkit-box-orient: vertical;
		word-wrap:break-word;
		white-space: pre-wrap;
		
		font-size: 20px;
		color:#505050;
		line-height: 36px;
	}
	
	.theaterOneM-message p{
		width: 100%;
		height: 250px;
	}
	.theaterOneM-message p img{
		max-height: 250px;
	}
	.error-img{
		width: 429px;
		height: 300px;
		margin-top: 100px;
	}
	.error-message{
		margin-top: 60px;
		margin-bottom: 50px;
		
		font-size: 30px;
		color: #073190;
		line-height: 40px;
	}
	.elcarouselheight{
		width: 5.8rem;
		height: 315px;
	}
	.elcarouselheight .el-carousel--horizontal{
		display: inline;
	}
	.elcarouselheight .el-carousel__container{
		width: 5.8rem;
		display: inline-block;
	}
</style>